<template>
  <div class="th-card" :class="classes" @click="clickEvent">
    <div class="th-card__header" :class="headerClasses" v-if="$slots.header">
      <slot name="header" />
    </div>
    <div
      class="th-card__body"
      :class="bodyClasses"
      v-if="$slots.body || $slots.default"
    >
      <slot />
      <slot name="body" />
    </div>
    <div
      class="th-card__footer"
      :class="[`th-card__footer-text-${footerTextAlign}`, footerClasses]"
      v-if="$slots.footer"
    >
      <slot name="footer" />
    </div>
  </div>
</template>

<script>
export default {
  name: "th-card",
  props: {
    collapse: Boolean,
    disabled: Boolean,
    footerTextAlign: {
      type: String,
      default: "left",
    },
    headerLine: Boolean,
    headerClasses: String,
    bodyClasses: String,
    footerClasses: String,
  },
  computed: {
    classes() {
      return {
        "th-card--no-header": !this.$slots.header,
        "th-card--no-footer": !this.$slots.footer,
        "th-card--no-body": !this.$slots.body,
        "th-card--collapse": this.collapse,
        "th-card--disabled": this.disabled,
        "th-card--header-line": this.headerLine,
      };
    },
  },
  methods: {
    clickEvent() {
      if (this.disabled) return;

      this.$emit("click");
    },
  },
};
</script>

<style lang="scss" scoped>
.th-card {
  background: $white;
  transition: all 0.5s;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  @include elevation1();
  border-radius: 16px;
  border: none;

  &__header {
    padding: 24px 24px 0;
  }

  &__header,
  &__body,
  &__footer {
    &--no-padding {
      padding: 0;
    }
  }

  &__header {
    font-weight: bold;
  }

  &__body {
    flex-grow: 1;
    padding: 24px;
    border-bottom: 1px solid $neutral-mid;
  }

  &__footer {
    padding: 0 8px 8px;
    text-align: left;

    &-text-right {
      text-align: right;
    }
  }

  &--header-line &__body {
    border-top: 1px solid $neutral-mid;
  }

  &--collapse &__body,
  &--collapse &__footer {
    display: none;
  }

  &--no-header &__body {
    border-top: none;
    padding-top: 24px;
  }

  &--no-footer &__body {
    border-bottom: none;
    padding-bottom: 24px;
  }

  &--disabled {
    background: transparent;
    color: $dark !important;
    filter: grayscale(100%);
  }
  &:hover {
    @include elevation2();
  }
}
</style>
